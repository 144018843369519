import React from 'react';

import QualityContent from './QualityContent';
import Carousel from '../../../Components/Carousel/Carousel';
import Image from '../../../Components/Image/Image';

import MOBILE_IMG_1_PNG from '../../../Assets/About/pic1.png';
import MOBILE_IMG_1_WEBP from '../../../Assets/About/pic1.webp';
import MOBILE_IMG_2_PNG from '../../../Assets/About/pic2.png';
import MOBILE_IMG_2_WEBP from '../../../Assets/About/pic2.webp';
import MOBILE_IMG_3_PNG from '../../../Assets/About/pic3.png';
import MOBILE_IMG_3_WEBP from '../../../Assets/About/pic3.webp';
import MOBILE_IMG_4_PNG from '../../../Assets/About/pic4.png';
import MOBILE_IMG_4_WEBP from '../../../Assets/About/pic4.webp';

import {
  QUALITY_TEXT_1,
  QUALITY_TEXT_2,
  QUALITY_TEXT_4,
  QUALITY_TEXT_5,
  QUALITY_TEXT_6,
  QUALITY_TEXT_7,
  QUALITY_TEXT_8,
  QUALITY_TEXT_9,
  QUALITY_TEXT_10,
  QUALITY_TEXT_11
} from '../../../Constants';

import styles from './qualities.module.css';

const QUALITY_CONTENT = [
  {
    id: 1,
    heading: QUALITY_TEXT_4,
    info: QUALITY_TEXT_5,
    icon: 'tasks',
    pic: MOBILE_IMG_1_PNG,
    pic_webp: MOBILE_IMG_1_WEBP
  },
  {
    id: 2,
    heading: QUALITY_TEXT_6,
    info: QUALITY_TEXT_7,
    icon: 'video-camera',
    pic: MOBILE_IMG_2_PNG,
    pic_webp: MOBILE_IMG_2_WEBP
  },
  {
    id: 3,
    heading: QUALITY_TEXT_8,
    info: QUALITY_TEXT_9,
    icon: 'envelope',
    pic: MOBILE_IMG_3_PNG,
    pic_webp: MOBILE_IMG_3_WEBP
  },
  {
    id: 4,
    heading: QUALITY_TEXT_10,
    info: QUALITY_TEXT_11,
    icon: 'users',
    pic: MOBILE_IMG_4_PNG,
    pic_webp: MOBILE_IMG_4_WEBP
  }
];

class Qualities extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      iconNum: null
    };
    this.ref = React.createRef();
  }

  setIconNum = (iconNum) => {
    this.ref.current.goToSlide(iconNum);
  };

  setIconActive = (iconNum) => {
    this.setState({
      iconNum
    });
  };

  render() {
    return (
      <div className={`${styles.container} center`}>
        <div className={`${styles.image_box} center`}>
          <Carousel
            indicators={false}
            controls={false}
            interval={2000}
            ref={this.ref}
            onChange={this.setIconActive}>
            {QUALITY_CONTENT.map((content, index) => (
              <div className={styles.slide} key={content.id}>
                {/* <img
                  className={styles.slide_image}
                  src={content.pic}
                  alt="mobile_image"
                ></img> */}
                <Image
                  className={styles.slide_image}
                  src={content.pic}
                  webpImage={content.pic_webp}
                  alt="mobile_image"
                />
              </div>
            ))}
          </Carousel>
        </div>

        <div className={`${styles.content} center`}>
          <p className={`${styles.large_heading} heading_text`}>
            {QUALITY_TEXT_1}
          </p>
          <p className={`body_text`}>{QUALITY_TEXT_2}</p>

          <div className={styles.content_blocks}>
            {QUALITY_CONTENT.map((content, index) => (
              <QualityContent
                key={content.id}
                id={content.id}
                icon={content.icon}
                heading={content.heading}
                info={content.info}
                onClick={this.setIconNum}
                iconActive={this.state.iconNum === content.id}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Qualities;
