import React from 'react';

import Image from '../../../Components/Image/Image';

import MOBILE_IMG_PNG from '../../../Assets/About/events.png';
import MOBILE_IMG_WEBP from '../../../Assets/About/events.webp';

import {
  COMPATIBILITY_TEXT_1,
  COMPATIBILITY_TEXT_2,
  COMPATIBILITY_TEXT_3,
  COMPATIBILITY_TEXT_4,
  PLAYSTORE_LINK
} from '../../../Constants';

import styles from './compatibility.module.css';

const Compatibility = () => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.image_box}>
        {/* <img src={MOBILE_IMG_1} alt="sociana-events" /> */}
        <Image
          src={MOBILE_IMG_PNG}
          webpImage={MOBILE_IMG_WEBP}
          alt="sociana-events"
        />
      </div>
      <div className={styles.text_box}>
        <p className={`heading_text white_heading `}>{COMPATIBILITY_TEXT_1}</p>
        <p className={`body_text`}>{COMPATIBILITY_TEXT_2}</p>
        <p className={`body_text`}>{COMPATIBILITY_TEXT_3}</p>
        <a
          className={`center button_pm ${styles.download_button}`}
          href={PLAYSTORE_LINK}
          target="_blank"
          rel="noopener noreferrer">
          {COMPATIBILITY_TEXT_4}
        </a>
      </div>
    </div>
  </div>
);

export default Compatibility;
