import React from 'react';

import Icon from '../../../Components/Icon/Icon';

import styles from './qualities.module.css';

const QualityContent = (props) => (
  <div className={styles.content_block}>
    <div
      className={`${styles.sm_icon_box} ${styles.icon_box} ${
        props.iconActive === true ? styles.active : null
      } center`}
      onClick={() => props.onClick(props.id)}>
      <Icon icon={props.icon} size="lg" />
    </div>

    <div className={styles.text_box}>
      <p className={`sm_heading_text`}>{props.heading}</p>
      <p className={`body_text`}>{props.info}</p>
    </div>
  </div>
);

export default QualityContent;
