import React from 'react';

import Qualities from './Qualities';
import Compatibility from './Compatibility';

const About = () => (
  <div data-testid="about" id="about">
    <Qualities />
    <Compatibility />
  </div>
);

export default About;
